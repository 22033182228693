import arrayToTree from 'array-to-tree'
import {consola} from "consola";

export * from './faq'
export * from './products'

export const useAppGlobalData = () => {
  let settings: any = inject('globalSettings')
  let {getSingletonItem} = useDirectusItems();
  const {generateFaqDto, logger} = useFaqUtils(inject('globalSettings'))


  const sortTree = (tree: Array<any>, key: string, childrenProperty: string = "childs") => {
    if (Array.isArray(tree) && tree.length > 0) {
      return tree.sort((a: any, b: any) => a[key] - b[key]).map((item: any) => {
        let childs = item[childrenProperty]
        return {
          ...item,
          [childrenProperty]: sortTree(childs, key, childrenProperty)
        }
      })
    } else
      return []
  }

  const getDataMenuHeader = (languageCode: string) => {
    let menu_id = 1

    switch (languageCode) {
      case 'en-US':
        menu_id = 2
        break
      default:
        menu_id = 1
        break
    }

    let tree = arrayToTree(settings.menus[menu_id], {
      childrenProperty: 'childs',
      parentProperty: 'parent'
    })

    return sortTree(tree, "sort")

    // return useAsyncData(() =>
    //     getProductsMegaMenu(languageCode).then((categories) => {
    //         let { data } = categories
    //         let menus = settings.menus[menu_id]

    //         if (menus && Array.isArray(menus) && menus.length > 0) {
    //             menus = menus.map((item) => ({
    //                 ...item,
    //                 childs: item.url === '/en/product' || item.url === '/san-pham' ? data.value : []
    //             }))
    //         }

    //         return menus
    //     })
    // )
  }

  const getDataFooterMenu = (languageCode: string) => {
    let menu_id = 3

    switch (languageCode) {
      case 'en-US':
        menu_id = 4
        break
      default:
        menu_id = 3
        break
    }

    let tree = arrayToTree(settings.menus[menu_id], {
      childrenProperty: 'childs',
      parentProperty: 'parent'
    })
    return sortTree(tree, "sort")

  }

  const getFaqSettings = async (languageCode: string) => {
    return getSingletonItem({
      collection: 'global_settings',
      params: {
        fields: [
          '*',
          'intro_image.id',
          'intro_image.title',
          'intro_faqs.*',
          'intro_faqs.faqs_id.*',
          'intro_faqs.faqs_id.translations.*',
          'contact_faqs_image.id',
          'contact_faqs_image.title',
          'contact_faqs.*',
          'contact_faqs.faqs_id.translations.*',
          'contact_faqs.faqs_id.*',
        ],
      }
    }).then((data: any) => {
      let intro_faqs = [];
      let contact_faqs = [];

      if (data.intro_faqs && data.intro_faqs[0] && typeof data.intro_faqs[0].faqs_id === 'object') {
        // console.log(true)
        intro_faqs = data.intro_faqs.map((ite: any) => generateFaqDto(ite.faqs_id, languageCode))
      }
      if (data.contact_faqs && data.contact_faqs[0] && typeof data.contact_faqs[0].faqs_id === 'object') {
        // console.log(true)
        contact_faqs = data.contact_faqs.map((ite: any) => generateFaqDto(ite.faqs_id, languageCode))
      }
      return {
        ...data,
        intro_faqs,
        contact_faqs
      }
    })
      .catch((e: any) => {
        logger.error('Error getFaqSettings')
        logger.debug(e)
        return []
      })
  }

  return {
    getDataMenuHeader,
    getDataFooterMenu,
    getFaqSettings
  }
}
